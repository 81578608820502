import React from 'react';
import './Hero.css';
import bgImg from '../Images/bg-img.png';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="hero-container" style={{ backgroundImage: `url(${bgImg})` }}>
      <div className="hero-content">
        
        <h1>BUSINESS INNOVATION WITH IT EXPERTS</h1>
        <p>
        Unlock the potential of seamless integration with cutting-edge technologies. We provide innovative solutions that enhance connectivity and drive efficiency. Our expertise ensures a smooth, scalable experience for businesses across industries, enabling them to thrive in a connected world.
        </p>
        <div className="hero-buttons">
  <Link to="/contact">
    <button className="btn-primary">Get Started</button>
  </Link>
</div>

      </div>
      
    </div>
  );
};

export default Hero;

