import React, { useState } from 'react';
import Header from '../../../Components/Home/Header/Header';
import Testimonial from '../../../Components/Home/Testimonial/Testimonial';
import Logo from '../../../Components/Home/Logo/Logo';
import Footer from '../../../Components/Home/Footer/Footer';

const Domain = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const toggleExpand = (index) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const services = [
    {
      title: 'Health Care',
      image: '/assets/healthCare.jpg',
      content:
        'Electronic Health Records (EHR) Systems to manage patient data, medical history, and treatment plans. Healthcare Telemedicine: Platforms for remote consultation and diagnosis. Health Information Systems: Integration of various healthcare systems for seamless data exchange. Patient Portals: Platforms for patients to access their medical records and communicate with healthcare providers. Medical Imaging Systems: Advanced software for storing, analyzing, and sharing medical imaging data like X-rays, MRIs, and CT scans.',
    },
    {
      title: 'Banking',
      image: '/assets/banking.jpg',
      content:
        'Core Banking System Platforms to manage customer accounts, transactions, and banking operations. Online Banking: Web and mobile applications for banking services. Financial Banking Fraud Detection: AI and machine learning tools to detect and prevent fraudulent activities. Blockchain: For secure transactions and decentralized finance (DeFi) applications. Risk Management: Tools for assessing and managing financial risks. Payment Gateways: Secure platforms for processing online transactions and facilitating seamless digital payments.',
    },
    {
      title: 'Hospitality & Travel',
      image: '/assets/hospital1.webp',
      content:
        'Tools for managing hotel operations and guest services. Booking and Reservation Systems: Platforms for managing reservations and bookings. Hospitality & Travel Customer Experience Management: Tools for enhancing guest experiences and personalization. Travel Management Systems: Solutions for managing travel bookings and itineraries. Revenue Management Systems: Tools for optimizing pricing and revenue.',
    },
    {
      title: 'Transportation & Logistic',
      image: '/assets/logistic.jpg',
      content:
        'Tools for managing and tracking vehicle fleets. Transportation & Logistic Route Optimization: AI tools for optimizing delivery routes and schedules. Warehouse Management: Solutions for managing warehouse operations and inventory. Transportation Management Systems (TMS): Platforms for planning and executing the movement of goods. Telematics: Systems for monitoring vehicle performance and driver behavior. Supply Chain Visibility: Tools for tracking shipments and managing logistics.',
    },
    {
      title: 'Retail & ECom',
      image: '/assets/retail.jpg',
      content:
        'E-Commerce Platform Websites and apps for online shopping. Point of Sale (POS) Systems: Integrated systems for in-store sales transactions. Inventory Management: Solutions for tracking and managing stock levels. Retail & eCommerce Customer Relationship Management (CRM): Tools for managing customer interactions and sales. Personalization Engines: AI-driven systems for personalized shopping experiences.',
    },
    {
      title: 'E Governance',
      image: '/assets/e-governance.webp',
      content:
        'E-Government Solutions Platforms for providing government services online. Public Safety Systems: Tools for managing emergency services and public safety operations. Citizen Portals: Platforms for citizens to access government services and information. Data Management Systems: Solutions for managing government data and records. Smart City Solutions: IoT and AI technologies for managing urban infrastructure and services. Regulatory Compliance Tools: Systems for ensuring compliance with regulations and policies.',
    },
  ];

  return (
    <div>
      <Header />
      <div className="services-container1">
        {services.map((service, index) => (
          <div className="service-card1" key={index}>
            <img src={service.image} alt={service.title} className="service-image" />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">
              {expandedCards[index]
                ? service.content
                : `${service.content.substring(0, 100)}...`}
            </p>
            <button
              onClick={() => toggleExpand(index)}
              className="service-link"
            >
              {expandedCards[index] ? 'Read Less ←' : 'Read More →'}
            </button>
          </div>
        ))}
      </div>
      <Testimonial />
      <Logo />
      <Footer />
    </div>
  );
};

export default Domain;

