import React from 'react';
import './About.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faCloud, faLaptopCode, faPalette, faBullhorn, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

const services = [
  {
    id: 1,
    title: 'Artificial Intelligence',
    description: 'Artificial Intelligence (AI) helps businesses automate processes, enhance customer experiences, and make data-driven decisions. It allows companies to optimize operations and innovate faster.',
    icon: faBrain,
  },
  {
    id: 2,
    title: 'IOT Services',
    description: 'The Internet of Things (IoT) connects devices to the internet, enabling real-time data collection and analysis. This integration boosts efficiency, improves decision-making, and provides actionable insights.',
    icon: faCloud,
  },
  {
    id: 3,
    title: 'Software Development',
    description: 'Software development offers tailored solutions to meet specific business needs. Whether you need desktop apps, web apps, or enterprise systems, custom software development enhances functionality and user experience.',
    icon: faLaptopCode,
  },
  {
    id: 4,
    title: 'Web Designing',
    description: 'Web designing focuses on creating visually stunning, responsive websites that are easy to navigate. A well-designed website ensures a seamless user experience while effectively communicating your brand.',
    icon: faPalette,
  },
  {
    id: 5,
    title: 'Digital Marketing',
    description: 'Digital marketing strategies leverage online platforms such as social media, search engines, and email marketing. These tactics help businesses reach their target audience, increase visibility, and drive sales.',
    icon: faBullhorn,
  },
  {
    id: 6,
    title: 'Mobile Application',
    description: 'Mobile applications provide businesses with a direct channel to interact with customers on-the-go. By offering seamless, personalized experiences, mobile apps can significantly enhance user engagement and satisfaction.',
    icon: faMobileAlt,
  },
];

const About = () => {
  return (
    <div className="services-container">
      <h1 className="title">Ensuring Your Success Trusted IT Services Source</h1>
      <div className="services-wrapper">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <div className="icon">
              <FontAwesomeIcon icon={service.icon} size="2x" />
            </div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
