import React, { useState } from 'react';
import './Testimonial.css';
import bgImg from '../Images/white-bg.png';

const testimonials = [
    {
        name: 'Rakesh Padhy',
        role: 'Project Manager',
        feedback: "From the first consultation to the final delivery, the experience was seamless. I was initially struggling to find someone who could take my ideas and turn them into a functional, beautiful website that truly represents my brand, but this team did it effortlessly.They were able to understand my vision and added their expertise to elevate the project in ways I hadn’t anticipated.I can confidently say that my business has benefited greatly from having such a skilled and dedicated team build our online presence.",
        image: '/assets/test4.jpg', // Replace with your image URL
    },
    {
        name: 'Archana Mishra',
        role: 'Marketing Manager',
        feedback: "Working with this team was a game-changer for our marketing efforts. They understood our brand's vision and delivered a website that perfectly aligns with our goals. The seamless user experience and visually appealing design have boosted our customer engagement. Their professionalism, attention to detail, and timely delivery exceeded our expectations. I would highly recommend them to any marketing team looking for quality and innovation.",
        image: '/assets/test2.jpg', // Replace with your image URL
    },
    {
        name: 'Mohit Singh',
        role: 'Product Designer',
        feedback: "Collaboration with this team has been fantastic. They took the time to understand the intricacies of our product vision and turned it into an intuitive, user-friendly design. Their creativity, attention to detail, and technical expertise resulted in a product we're proud to launch. The team's responsiveness and dedication to quality were exceptional. I would recommend them to any designer looking for a reliable development partner, making the collaboration enjoyable. Will definitely work with them again.",
        image: '/assets/test1.jpg', // Replace with your image URL
    },
];

export default function Testimonial() {
    const [current, setCurrent] = useState(0);
    const length = testimonials.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    return (
        <div className="testimonial-container" style={{ backgroundImage: `url(${bgImg})` }}>
            <h2>Our Customers Review</h2>
            <div className="testimonial-slider">
                <button className="prev" onClick={prevSlide}>&#10094;</button>
                {testimonials.map((testimonial, index) => (
                    <div
                        className={index === current ? 'testimonial active' : 'testimonial'}
                        key={index}
                    >
                        {index === current && (
                            <>
                                <img 
                                    src={testimonial.image} 
                                    alt={testimonial.name} 
                                    className="testimonial-image" 
                                />
                                <blockquote>
                                    <p className="para" style={{ fontSize: "1.2rem" }}>
                                        "{testimonial.feedback}"
                                    </p>
                                </blockquote>
                                <h3 className="heading">{testimonial.name}</h3>
                                <span className="span">{testimonial.role}</span>
                                <div className="stars">
                                    <span>&#9733;&#9733;&#9733;&#9733;&#9733;</span>
                                </div>
                            </>
                        )}
                    </div>
                ))}
                <button className="next" onClick={nextSlide}>&#10095;</button>
            </div>
        </div>
    );
}
