import React, { useState } from 'react';
import Header from '../../../Components/Home/Header/Header';
import Testimonial from '../../../Components/Home/Testimonial/Testimonial';
import Logo from '../../../Components/Home/Logo/Logo';
import Footer from '../../../Components/Home/Footer/Footer';
import './ItService.css';

const ITService = () => {
    const [expanded, setExpanded] = useState({});

    const handleReadMore = (index) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const services = [
        {
            id: 1,
            title: 'Artificial Intelligence Services',
            imgSrc: '/assets/AI.jpg',
            fullDescription:
                'AI-as-a-Service (AlaaS) is a cloud-based delivery model that provides access to artificial intelligence capabilities without requiring extensive upfront investments in hardware, software, or data science expertise. AlaaS platforms offer a wide range of AI services, including machine learning, natural language processing, computer vision, and predictive analytics. With AIaaS, businesses can enhance decision-making, improve customer experiences, and automate time-consuming tasks, all while scaling AI capabilities effortlessly.',
        },
        {
            id: 2,
            title: 'IOT Services',
            imgSrc: '/assets/iot.jpg',
            fullDescription:
                'Internet of Things (IoT) refers to the interconnected network of physical devices, vehicles, home appliances, and other objects embedded with electronics, software, sensors, and network connectivity. IoT enables seamless data exchange, remote monitoring, automation, and control across industries. Businesses leverage IoT to optimize operations, reduce costs, and create smarter, more efficient solutions for everyday challenges.',
        },
        {
            id: 3,
            title: 'Software Development',
            imgSrc: '/assets/software.jpg',
            fullDescription:
                'By leveraging AI technologies like machine learning, natural language processing, and computer vision, you can automate tasks, improve customer experiences. From chatbots to predictive analytics and image recognition, software development integrates cutting-edge AI tools to enhance business processes, boost productivity, and deliver innovative solutions.',
        },
        {
            id: 4,
            title: 'Web Designing',
            imgSrc: '/assets/webdesigning.jpg',
            fullDescription:
                'Our websites are developed using the latest technologies and industry trends including ASP.net, PHP, MySQL, and SQL Server. Our team creates intuitive, visually appealing, and highly functional web designs tailored to meet the unique needs of businesses, ensuring a seamless user experience and high performance.',
        },
        {
            id: 5,
            title: 'Digital Marketing',
            imgSrc: '/assets/digitalmarketing.jpg',
            fullDescription:
                'Digital marketing is the practice of promoting products or services using digital channels like search engines, social media, email, and websites. It involves strategies such as SEO, PPC advertising, content marketing, social media marketing, and email marketing, helping businesses reach a wider audience and drive engagement.',
        },
        {
            id: 6,
            title: 'Mobile Application',
            imgSrc: '/assets/mobile-application.jpg',
            fullDescription:
                'We have dedicated mobile app development programmers who have ample experience in this field. Our mobile app development services follow a structured and efficient approach to deliver high-quality applications on time, ensuring seamless functionality and an exceptional user experience.',
        },
    ];

    return (
        <div>
            <Header />
            <div className="services-container1">
                {services.map((service, index) => (
                    <div key={service.id} className="service-card1">
                        <img src={service.imgSrc} alt={service.title} className="service-image" />
                        <h3 className="service-title">{service.title}</h3>
                        <p className="service-description">
                            {expanded[index] ? service.fullDescription : `${service.fullDescription.substring(0, 150)}...`}
                        </p>
                        <button
                            onClick={() => handleReadMore(index)}
                            className="service-link"
                        >
                            {expanded[index] ? 'Show Less ←' : 'Read More →'}
                        </button>
                    </div>
                ))}
            </div>
            <Testimonial />
            <Logo />
            <Footer />
        </div>
    );
};

export default ITService;
