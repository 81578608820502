import React, { useState } from "react";
import Header from "../Components/Home/Header/Header";
import Footer from "../Components/Home/Footer/Footer";
import Logo from "../Components/Home/Logo/Logo";
import { FaCheckCircle } from "react-icons/fa";
import "./Career.css";

const Career = () => {
    const [result, setResult] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending...");
        const formData = new FormData(event.target);

        formData.append("access_key", "74d9130d-a43f-4c55-907f-3959b09a829a");
        if (selectedFile) {
            formData.append("cv", selectedFile);
        }

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData,
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            setShowModal(true); // Show modal on successful submission
            event.target.reset();
            setSelectedFile(null);
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header />
            <div className="career-container">
                <div className="career-form">
                    <h2>Choose Your Career</h2>
                    <form onSubmit={onSubmit}>
                        <input type="text" name="name" placeholder="Enter your name" required />
                        <input type="email" name="email" placeholder="Enter your email" required />
                        <input type="number" name="phone" placeholder="Enter your phone number" required />
                        <select name="position" required>
                            <option value="">Select a position</option>
                            <option value="Fullstack Developer">Fullstack Developer</option>
                            <option value="Python Developer">Python Developer</option>
                            <option value="AI/ML Engineer">AI/ML Engineer</option>
                            <option value="Cloud Architect">Cloud Architect</option>
                            <option value="Backend Developer">Backend Developer</option>
                            <option value="Ethical Hacker">Ethical Hacker</option>
                        </select>
                        <textarea name="message" placeholder="Tell Us About Yourself" required></textarea>
                        <button type="submit">Submit Form</button>
                    </form>
                    <span className="result">{result}</span>
                </div>
            </div>
            <Logo />
            <Footer />

            {showModal && (
                <div className="modal1">
                    <div className="modal-content1">
                        <span className="close1" onClick={closeModal}>
                            &times;
                        </span>
                        <div className="modal-body1">
                            <div className="success-icon1">
                                <FaCheckCircle />
                            </div>
                            <h3>Success!</h3>
                            <p>Form Submitted Successfully</p>
                            <button className="close-button1" onClick={closeModal}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Career;
