import React from 'react';
import './Footer.css';
import { FaFacebook } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin, FaInstagram } from "react-icons/fa6";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa"; 
import { IoMdMail } from "react-icons/io";
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        
        <div className="footer-column get-in-touch">
          <h4 className="footer-heading" style={{color:"#0077ff"}}>Get In Touch</h4>
          <ul className="get-in-touch-info">
            <li><FaMapMarkerAlt className="contact-icon" /> Sailashree Vihar, Bhubaneswar, Odisha 751021</li>
            <li><IoMdMail className="contact-icon" /> info@totaltechnology <br />system.com</li>
            <li><FaPhoneAlt className="contact-icon" /> +91-8260205005</li>
          </ul>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading" style={{color:"#0077ff"}}>Company</h4>
          <ul>
            <li><Link to="/Overview">About</Link></li>
          
            <li><Link to="/Domain">Industries</Link></li>
            <li><Link to="/Contact">Contact</Link></li>
            <li><Link to="/Career">Career</Link></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading" style={{color:"#0077ff"}}>Newsletter</h4>
          <p style={{color:"#B6B9C0"}}>Subscribe to our Latest Newsletter</p>
          <div className="footer-newsletter" style={{marginTop:"10px"}}>
            <input type="email" placeholder="Enter Your E-Mail" />
            <a href='https://www.youtube.com/@TotalTechnologySystem' target='blank'><button className="footer-newsletter-btn">Subscribe</button> </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">

        <p style={{ color: "rgb(182, 185, 192)" }}>
  ©Total Technology Systems {new Date().getFullYear()}. All Rights Reserved.
</p>

        <div className="footer-socials">
          <a href="https://www.facebook.com/people/Total-Technology-System/61560492723875/" className="social-icon" target='blank'><FaFacebook /></a>
          <a href="https://x.com/total526501" target='blank' className="social-icon"><BsTwitterX /></a>
          <a href="http://www.linkedin.com/in/total-technology-system-523363310" className="social-icon"  target='blank'><FaLinkedin /></a>
          <a href="https://www.instagram.com/totaltechnologysystem/" target='blank' className="social-icon" ><FaInstagram /></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
