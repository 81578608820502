import React, { useState } from "react";
import "./Contact.css";
import Header from "../Components/Home/Header/Header";
import Footer from "../Components/Home/Footer/Footer";
import Logo from "../Components/Home/Logo/Logo";
import { FaCheckCircle } from "react-icons/fa";

const Contact = () => {
  const [result, setResult] = useState("");
  const [showModal, setShowModal] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    const formData = new FormData(event.target);

    formData.append("access_key", "74d9130d-a43f-4c55-907f-3959b09a829a");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      setShowModal(true);
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header />
      <div className="contact-container">
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={onSubmit}>
            <input type="text" name="name" placeholder="Enter your name" required />
            <input type="email" name="email" placeholder="Enter your email" required />
            <input type="text" name="phone" placeholder="Enter your number" required />
            <textarea name="message" placeholder="Write a message" required></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14964.220564708909!2d85.8103949!3d20.3393406!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909005f32b597%3A0x4c836a6a55bee903!2sTotal%20technology%20system!5e0!3m2!1sen!2sin!4v1729383042928!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
      <Logo />
      <Footer />

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <div className="modal-body">
              <FaCheckCircle className="success-icon" />
              <h3>Success!</h3>
              <p>{result}</p>
              <button className="close-button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
