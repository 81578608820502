import React, { useState } from 'react';
import './Header.css';
import { IoLocationOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { FaFacebook, FaChevronDown, FaBars, FaTimes } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin, FaInstagram } from "react-icons/fa6";
import logo from '../Images/tts_logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [dropdown, setDropdown] = useState({
    about: false,
    services: false,
    industries: false,
  });
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleMouseEnter = (menu) => {
    setDropdown((prevState) => ({
      ...prevState,
      [menu]: true
    }));
  };

  const handleMouseLeave = (menu) => {
    setDropdown((prevState) => ({
      ...prevState,
      [menu]: false
    }));
  };

  return (
    <header>

      <div className="top-bar">
        <div className="container">
          <div className="left-side">
            <span><IoLocationOutline /> F-25, Chandaka Industrial Estate, Bhubaneswar, 751021</span>
            <span><CiMail /> info@totaltechnologysystem.com</span>
          </div>
          <div className="right-side">
            <div className="social-icons">
              <a href="https://www.facebook.com/people/Total-Technology-System/61560492723875/" target='blank'><FaFacebook /></a>
              <a href="https://x.com/total526501" target='blank'><BsTwitterX /></a>
              <a href="http://www.linkedin.com/in/total-technology-system-523363310" target='blank'><FaLinkedin /></a>
              <a href="https://www.instagram.com/totaltechnologysystem/" target='blank'><FaInstagram /></a>
            </div>
          </div>
        </div>
      </div>


      <div className="main-nav">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="TopTech Logo" />
            <h1>Total<span> Technology</span> System</h1>
          </div>


          <div className="nav-icon" onClick={handleNavToggle}>
            {isNavOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </div>


          <nav className={isNavOpen ? "nav-open" : ""}>
            <ul className="nav-links">
              <li><Link to="/">Home</Link></li>


              <li
                className="dropdown"
                onMouseEnter={() => handleMouseEnter('about')}
                onMouseLeave={() => handleMouseLeave('about')}
              >
                <Link to='/overview'>
                  About <FaChevronDown size={12} />
                </Link>
                {dropdown.about && (
                  <ul className="dropdown-menu">
                    <li><Link to="/overview">Overview</Link></li>
                    <li><Link to="/testimonials">Testimonials</Link></li>
                    <li><Link to="/Awards">Awards</Link></li>
                  </ul>
                )}
              </li>


              <li
                className="dropdown"
                onMouseEnter={() => handleMouseEnter('services')}
                onMouseLeave={() => handleMouseLeave('services')}
              >
                <Link to='/ItService'>
                  Services <FaChevronDown size={12} />
                </Link>
                {dropdown.services && (
                  <ul className="dropdown-menu">
                    <li><Link to="/ItService">IT Service</Link></li>
                    <li><Link to="/GeospatialService">Geospatial Service</Link></li>
                  </ul>
                )}
              </li>


              <li
                className="dropdown"
                onMouseEnter={() => handleMouseEnter('industries')}
                onMouseLeave={() => handleMouseLeave('industries')}
              >
                <Link to='/Domain'>
                  Industries <FaChevronDown size={12} />
                </Link>
                {dropdown.industries && (
                  <ul className="dropdown-menu">
                    <li><Link to="/Domain">Domain Offerings</Link></li>
                    
                  </ul>
                )}
              </li>

              <li><Link to="/Career">Career</Link></li>
              <li><Link to="/Contact">Contact</Link></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
    
  );
};

export default Header;

